var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Virtual Slides" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVirtual) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "swiper",
        {
          ref: "mySwiper15",
          staticClass: "swiper-virtual",
          attrs: {
            options: _vm.swiperOptions,
            dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr"
          }
        },
        [
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination"
          }),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next"
          }),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev"
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "demo-inline-spacing justify-content-center" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "font-small-3",
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.prependSlides($event)
                }
              }
            },
            [_vm._v(" Prepend 2 Slides ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "font-small-3",
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toSlide(0)
                }
              }
            },
            [_vm._v(" Slide 1 ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "font-small-3",
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toSlide(250)
                }
              }
            },
            [_vm._v(" Slide 250 ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "font-sm-3",
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.toSlide(500)
                }
              }
            },
            [_vm._v(" Slide 500 ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              staticClass: "font-small-3",
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.appendSlide($event)
                }
              }
            },
            [_vm._v(" Append Slide ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Gallery" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeGallery) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "swiper",
        {
          ref: "swiperTop",
          staticClass: "swiper-gallery gallery-top",
          attrs: {
            options: _vm.swiperOptions,
            dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr"
          }
        },
        [
          _vm._l(_vm.swiperData, function(data, index) {
            return _c(
              "swiper-slide",
              { key: index },
              [_c("b-img", { attrs: { src: data.img, fluid: "" } })],
              1
            )
          }),
          _c("div", {
            staticClass: "swiper-button-next swiper-button-white",
            attrs: { slot: "button-next" },
            slot: "button-next"
          }),
          _c("div", {
            staticClass: "swiper-button-prev swiper-button-white",
            attrs: { slot: "button-prev" },
            slot: "button-prev"
          })
        ],
        2
      ),
      _c(
        "swiper",
        {
          ref: "swiperThumbs",
          staticClass: "swiper gallery-thumbs",
          attrs: { options: _vm.swiperOptionThumbs }
        },
        _vm._l(_vm.swiperData, function(data, index) {
          return _c(
            "swiper-slide",
            { key: index },
            [_c("b-img", { attrs: { src: data.img, fluid: "" } })],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Parallax" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeParallax) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "swiper",
        {
          staticClass: "swiper-parallax",
          attrs: {
            options: _vm.swiperOptions,
            dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "parallax-bg",
              attrs: { slot: "parallax-bg", "data-swiper-parallax": "-23%" },
              slot: "parallax-bg"
            },
            [
              _c("b-img", {
                staticClass: "img-fluid",
                attrs: {
                  src: require("@/assets/images/banner/parallax-4.jpg"),
                  alt: "banner"
                }
              })
            ],
            1
          ),
          _vm._l(_vm.swiperData, function(data, index) {
            return _c("swiper-slide", { key: index }, [
              _c(
                "div",
                {
                  staticClass: "title",
                  attrs: { "data-swiper-parallax": "-300" }
                },
                [_vm._v(" " + _vm._s(data.title) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "subtitle",
                  attrs: { "data-swiper-parallax": "-200" }
                },
                [_vm._v(" " + _vm._s(data.subtitle) + " ")]
              ),
              _c(
                "div",
                {
                  staticClass: "text",
                  attrs: { "data-swiper-parallax": "-100" }
                },
                [_c("b-card-text", [_vm._v(" " + _vm._s(data.text) + " ")])],
                1
              )
            ])
          }),
          _c("div", {
            staticClass: "swiper-pagination",
            attrs: { slot: "pagination" },
            slot: "pagination"
          }),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next"
          }),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
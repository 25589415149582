var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("swiper-default"),
          _c("swiper-navigation"),
          _c("swiper-pagination"),
          _c("swiper-progress"),
          _c("swiper-multiple-slides"),
          _c("swiper-multi-row-slides"),
          _c("swiper-centered-slides"),
          _c("swiper-centered-slides2"),
          _c("swiper-fade-effect"),
          _c("swiper-cube"),
          _c("swiper-coverflow-effect"),
          _c("swiper-autoplay"),
          _c("swiper-gallery"),
          _c("swiper-parallax"),
          _c("swiper-lazy"),
          _c("swiper-responsive"),
          _c("swiper-virtual")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Default" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDefault) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "swiper",
        {
          ref: "mySwiper",
          attrs: { dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr" }
        },
        _vm._l(_vm.swiperData, function(data) {
          return _c(
            "swiper-slide",
            { key: data.img },
            [_c("b-img", { attrs: { src: data.img, fluid: "" } })],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
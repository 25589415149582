var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      staticClass: "bg-transparent shadow-none",
      attrs: { title: "Centered Slides Option-1" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCenterSlides) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "swiper",
        {
          staticClass: "swiper-centered-slides p-1",
          attrs: {
            options: _vm.swiperOptions,
            dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr"
          }
        },
        [
          _vm._l(_vm.swiperData, function(data) {
            return _c(
              "swiper-slide",
              { key: data.text, staticClass: "rounded swiper-shadow" },
              [
                _c("feather-icon", { attrs: { icon: data.icon, size: "28" } }),
                _c("div", { staticClass: "swiper-text pt-md-1 pt-sm-50" }, [
                  _vm._v(" " + _vm._s(data.text) + " ")
                ])
              ],
              1
            )
          }),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next"
          }),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }